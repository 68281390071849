
/*
|--------------------------------------------------------------------------
| Custom Javascript code
|--------------------------------------------------------------------------
|
| Now that you configured your website, you can write additional Javascript
| code inside the following function. You might want to add more plugins and
| initialize them in this file.
|
*/

jQuery(function($) {
  /*template a-propos*/
  $('.nav-item a:first').addClass('active');
  $('.tab-content div:first').addClass('show active');
  $('.fixheight-box-services').matchHeight();
  /*template candidat(emploi) + index*/
  $('.fixheight-box-candidat,.fixheight-box-emploi').matchHeight();
  $('.fixheight-body').matchHeight();
  /*index*/
  $('.fixheight-box-industry').matchHeight();
  /*a propos*/
  $('.fixheight-box-header').matchHeight();
});
$(document).on('click', '.close-navbar', function() {
  page.navbarClose();
});
$(document).on('click', '#nav-Candidat', function() {
  $('.services-section').addClass('hide_section');
});
$(document).on('click', '#nav-Client', function() {
  $('.services-section').removeClass('hide_section');
});
$(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();
    $('html, body').animate({
        scrollTop: $('#tabIndustry,#section-apply').offset().top
    }, 500);
});

/*Custom DOM for: Upload file button*/
document.body.onload = addElement;
var lang= document.documentElement.lang;

function addElement () {
  // create a new div element
  var newDiv = document.createElement("button");
  newDiv.classList.add('browse-btn');
  // and give it some content
  if(lang === "fr-FR"){
    var newContent = document.createTextNode("Choisir un fichier");
  } else {
    var newContent = document.createTextNode("Choose a file");
  }

  // add the text node to the newly created div
  newDiv.appendChild(newContent);

  var newSpan = document.createElement("span");
  newSpan.classList.add('file-info');
  if(lang === "fr-FR"){
    var newSpanContent = document.createTextNode("Aucun fichier choisi");
  }else{
    var newSpanContent = document.createTextNode("No file selected");
  }
  newSpan.appendChild(newSpanContent);

  // add the newly created element and its content into the DOM
  var inputs = document.getElementsByTagName("input");
  for(var i = 0; i < inputs.length; i++) {
    if(inputs[i].type.toLowerCase() == 'file') {
        //console.log(inputs[i]);
        inputs[i].setAttribute("id", "real-input");
        inputs[i].parentNode.insertBefore(newDiv, inputs[i].nextSibling);

     var ref = document.querySelector('button.browse-btn');
     ref.parentNode.insertBefore(newSpan, ref.nextSibling);

     const uploadButton = document.querySelector('.browse-btn');
     const fileInfo = document.querySelector('.file-info');
     const realInput = document.getElementById('real-input');

     uploadButton.addEventListener('click', function() {
       realInput.click();
     });

     realInput.addEventListener('change',function() {
       const name = realInput.value.split(/\\|\//).pop();
       const truncated = name.length > 20
         ? name.substr(name.length - 20)
         : name;

       fileInfo.innerHTML = truncated;
     });

   }
  }
}
